body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root, #root__inner {
  height: 100%;
  background: #000;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

a, a:hover {
  color: inherit;
}

input {
  outline: none!important;
  box-shadow: none!important;
  color: inherit;
}

.navbar {
  background: inherit;
}

.navbar-brand {
  font-weight: 700;
}

.fixed-top {
  z-index: 2;
}

.nav-item-bold, a.nav-item-bold:hover {
  font-weight: bold;
  text-decoration: none;
}

/* home */

.home--left, .home--right {
  display: inline-block;
  vertical-align: top;
}

.home--left {
  max-width: 30%;
}

.home--right {
  max-width: 70%;
  box-sizing: border-box;
  padding-left: 20px;
}

.loading {
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
}


/* paper */

.theme {
  min-height: 100%;
  overflow: auto;
  position: relative;
  color: #000000;
}

.container {
  max-width: 700px;
  padding-top: 70px;
}

.theme .navbar::after,
.theme::after {
  content: '';
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  mix-blend-mode: lighten;
}

.theme .btn {
  background-color: transparent!important;
  color: #000;
  border-color: #000;
}

.theme .btn-filled {
  background-color: #000!important;
  color: #fff;
}

.theme .btn-cancel {
  opacity: .4;
}

[class*="--dark"] {
  color: #ffffff;
}

[class*="--dark"] .navbar::after,
[class*="--dark"]::after {
  mix-blend-mode: darken;
}

[class*="--dark"] .btn {
  color: #fff;
  border-color: #fff;
}

[class*="--dark"] .btn-filled {
  background-color: #fff!important;
}

.paper__name {
  font-style: italic;
}

.paper__title {
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.5rem;
}

.paper__bar {
  width: 40px;
  margin: 20px 0 30px;
  border-bottom: 5px solid;
}

.paper__date {
  font-weight: 700;
  font-size: 0.9rem;
  opacity: .3;
}

.paper__url {
  font-size: 0.9rem;
  opacity: .5;
}

.paper__content {
  line-height: 1.8;
  font-size: 1.2rem;
}

.paper__author {
  font-weight: 700;
  font-size: 0.9rem;
  opacity: .7;
}

.paper__desc {
  font-size: 0.9rem;
  opacity: .7;
}

.paper__quickLinks div {
  display: inline-block;
  margin: 8px;
  margin-right: 20px;
}

.paper__quickLinks a {
  border-radius: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center!important;
          align-items: center!important;
}

.paper__quickLinks a:hover {
  text-decoration: none;
}

.paper__quickLinks a i {
  margin: 0 auto;
}

.paper__image {
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
}
.paper__image img {
   mix-blend-mode: multiply;
  -webkit-filter: grayscale(100%) contrast(1.2);
  filter: grayscale(100%) contrast(1);
  width: 100%;
}

@media (min-width: 1000px) {
  .home .container {
    max-width: 1000px;
  }

  .home .paper__image {
    width: 300px;
    display: inline-block;
  }

  .home .paper__body {
    display: inline-block;
    width: 600px;
    margin-left: 50px;
    vertical-align: top;
  }
}


[class*="--dark"] .paper__image img{
  mix-blend-mode: screen;
}

.nav-bottom .nav-item.disabled {
  opacity: .25;
}

/* List */

.list-item {
  margin-bottom: 25px;
}

.list-item-title {
  font-weight: bold;
  font-size: 1.3rem;
  padding: 0;
  display: inline-block;
}

.list-item-buttons {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  opacity: .5;
}

.list-item-pub {
  font-size: .875rem;
  padding: .25rem 0;
}

/* Settings */

.paper__settings .form-control {
  background: transparent;
  border: none;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: inherit;
}

/* editor */

.paper__editor input {
  background: transparent;
  border: none;
  width: 100%;
}

.paper__editor input[name="cover"] {
  width: calc(100% - 80px);
  vertical-align: middle;
}

#paper__file {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
}

.cover__preview {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 300px;
}

#coverModal input {
  background: #000;
  border: 0;
  padding: 10px 15px;
  border-radius: .25rem;
  color: #fff;
}

#coverModal input::-webkit-input-placeholder {
  color: #aaa;
}

#coverModal input::-ms-input-placeholder {
  color: #aaa;
}

#coverModal input::placeholder {
  color: #aaa;
}

#coverModal .btn-okay {
  background: #00ff88!important;
  color: #000!important;
  border: none;
}

#coverModal .btn-cancel {
  background: #ff4842!important;
  color: #fff!important;
  border: none;
  opacity: 1;
}

#styleControls {
  /* position: fixed; */
  /* bottom: 0; */
  /* left: 0; */
  /* width: 100%; */
  background: inherit;
  padding: 10px 20px;
  color: inherit;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  /* margin-bottom: 5px; */
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: inline-block;
  
}

.RichEditor-styleButton {
  /* color: #999; */
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
  opacity: .5;
}

.RichEditor-activeButton {
  /* color: #5890ff; */
  font-weight: bold;
  opacity: 1;
}

.public-DraftEditorPlaceholder-root {
  color: inherit;
  opacity: .3;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 3px solid;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  font-weight: bold;
  margin: 16px 0;
  font-size: 1.3rem;
  padding: 10px 20px;
}

.timeago {
  font-size: 14px;
  opacity: .5;
}


/* themes */

/* https://webdesignerwall.com/tutorials/create-spotify-colorizer-effects-css-blend-modes */

.theme__mono {
  background-color: #ffffff;
}
.theme__mono .btn-filled {
  color: #ffffff;
}
.theme__mono .navbar::after,
.theme__mono::after{
  background-color: #000000;
}
.theme__mono input::-webkit-input-placeholder {
  color: #000000;
  opacity: .5;
}
.theme__mono input::-ms-input-placeholder {
  color: #000000;
  opacity: .5;
}
.theme__mono input::placeholder {
  color: #000000;
  opacity: .5;
}

.theme__mono--dark {
  background-color: #000000;
}
.theme__mono--dark .btn-filled {
  color: #000000;
}
.theme__mono--dark .navbar::after,
.theme__mono--dark::after{
  background-color: #ffffff;
}
.theme__mono--dark input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: .5;
}
.theme__mono--dark input::-ms-input-placeholder {
  color: #ffffff;
  opacity: .5;
}
.theme__mono--dark input::placeholder {
  color: #ffffff;
  opacity: .5;
}

.theme__spotify {
  background-color: #00ff36;
}
.theme__spotify .btn-filled {
  color: #00ff36;
}
.theme__spotify .navbar::after,
.theme__spotify::after{
  background-color: #23278a;
}
.theme__spotify input::-webkit-input-placeholder {
  color: #23278a;
  opacity: .5;
}
.theme__spotify input::-ms-input-placeholder {
  color: #23278a;
  opacity: .5;
}
.theme__spotify input::placeholder {
  color: #23278a;
  opacity: .5;
}

.theme__spotify--dark {
  background-color: #23278a;
}
.theme__spotify--dark .btn-filled {
  color: #23278a;
}
.theme__spotify--dark .navbar::after,
.theme__spotify--dark::after{
  background-color: #00ff36;
}
.theme__spotify--dark input::-webkit-input-placeholder {
  color: #00ff36;
  opacity: .5;
}
.theme__spotify--dark input::-ms-input-placeholder {
  color: #00ff36;
  opacity: .5;
}
.theme__spotify--dark input::placeholder {
  color: #00ff36;
  opacity: .5;
}

.theme__coffee {
  background-color: #fffcce;
}
.theme__coffee .btn-filled {
  color: #fffcce;
}
.theme__coffee .navbar::after,
.theme__coffee::after{
  background-color: #733611;
}
.theme__coffee input::-webkit-input-placeholder {
  color: #733611;
  opacity: .5;
}
.theme__coffee input::-ms-input-placeholder {
  color: #733611;
  opacity: .5;
}
.theme__coffee input::placeholder {
  color: #733611;
  opacity: .5;
}

.theme__coffee--dark {
  background-color: #733611;
}
.theme__coffee--dark .btn-filled {
  color: #733611;
}
.theme__coffee--dark .navbar::after,
.theme__coffee--dark::after{
  background-color: #fffcce;
}
.theme__coffee--dark input::-webkit-input-placeholder {
  color: #fffcce;
  opacity: .5;
}
.theme__coffee--dark input::-ms-input-placeholder {
  color: #fffcce;
  opacity: .5;
}
.theme__coffee--dark input::placeholder {
  color: #fffcce;
  opacity: .5;
}


.theme__berry {
  background-color: #ffbcce;
}
.theme__berry .btn-filled {
  color: #ffbcce;
}
.theme__berry .navbar::after,
.theme__berry::after{
  background-color: #2016a5;
}
.theme__berry input::-webkit-input-placeholder {
  color: #2016a5;
  opacity: .5;
}
.theme__berry input::-ms-input-placeholder {
  color: #2016a5;
  opacity: .5;
}
.theme__berry input::placeholder {
  color: #2016a5;
  opacity: .5;
}

.theme__berry--dark {
  background-color: #2016a5;
}
.theme__berry--dark .btn-filled {
  color: #2016a5;
}
.theme__berry--dark .navbar::after,
.theme__berry--dark::after{
  background-color: #ffbcce;
}
.theme__berry--dark input::-webkit-input-placeholder {
  color: #ffbcce;
  opacity: .5;
}
.theme__berry--dark input::-ms-input-placeholder {
  color: #ffbcce;
  opacity: .5;
}
.theme__berry--dark input::placeholder {
  color: #ffbcce;
  opacity: .5;
}


.theme__peach {
  background-color: #ffd6b0;
}
.theme__peach .btn-filled {
  color: #ffd6b0;
}
.theme__peach .navbar::after,
.theme__peach::after{
  background-color: #002eab;
}
.theme__peach input::-webkit-input-placeholder {
  color: #002eab;
  opacity: .5;
}
.theme__peach input::-ms-input-placeholder {
  color: #002eab;
  opacity: .5;
}
.theme__peach input::placeholder {
  color: #002eab;
  opacity: .5;
}

.theme__peach--dark {
  background-color: #002eab;
}
.theme__peach--dark .btn-filled {
  color: #002eab;
}
.theme__peach--dark .navbar::after,
.theme__peach--dark::after{
  background-color: #ffd6b0;
}
.theme__peach--dark input::-webkit-input-placeholder {
  color: #ffd6b0;
  opacity: .5;
}
.theme__peach--dark input::-ms-input-placeholder {
  color: #ffd6b0;
  opacity: .5;
}
.theme__peach--dark input::placeholder {
  color: #ffd6b0;
  opacity: .5;
}


.theme__cabin {
  background-color: #ffe5e2;
}
.theme__cabin .btn-filled {
  color: #ffe5e2;
}
.theme__cabin .navbar::after,
.theme__cabin::after{
  background-color: #1a5442;
}
.theme__cabin input::-webkit-input-placeholder {
  color: #1a5442;
  opacity: .5;
}
.theme__cabin input::-ms-input-placeholder {
  color: #1a5442;
  opacity: .5;
}
.theme__cabin input::placeholder {
  color: #1a5442;
  opacity: .5;
}

.theme__cabin--dark {
  background-color: #1a5442;
}
.theme__cabin--dark .btn-filled {
  color: #1a5442;
}
.theme__cabin--dark .navbar::after,
.theme__cabin--dark::after{
  background-color: #ffe5e2;
}
.theme__cabin--dark input::-webkit-input-placeholder {
  color: #ffe5e2;
  opacity: .5;
}
.theme__cabin--dark input::-ms-input-placeholder {
  color: #ffe5e2;
  opacity: .5;
}
.theme__cabin--dark input::placeholder {
  color: #ffe5e2;
  opacity: .5;
}


.theme__mint {
  background-color: #beefd1;
}
.theme__mint .btn-filled {
  color: #beefd1;
}
.theme__mint .navbar::after,
.theme__mint::after{
  background-color: #673273;
}
.theme__mint input::-webkit-input-placeholder {
  color: #673273;
  opacity: .5;
}
.theme__mint input::-ms-input-placeholder {
  color: #673273;
  opacity: .5;
}
.theme__mint input::placeholder {
  color: #673273;
  opacity: .5;
}

.theme__mint--dark {
  background-color: #673273;
}
.theme__mint--dark .btn-filled {
  color: #673273;
}
.theme__mint--dark .navbar::after,
.theme__mint--dark::after{
  background-color: #beefd1;
}
.theme__mint--dark input::-webkit-input-placeholder {
  color: #beefd1;
  opacity: .5;
}
.theme__mint--dark input::-ms-input-placeholder {
  color: #beefd1;
  opacity: .5;
}
.theme__mint--dark input::placeholder {
  color: #beefd1;
  opacity: .5;
}


.theme__mustard {
  background-color: #f7f211;
}
.theme__mustard .btn-filled {
  color: #f7f211;
}
.theme__mustard .navbar::after,
.theme__mustard::after{
  background-color: #692790;
}
.theme__mustard input::-webkit-input-placeholder {
  color: #692790;
  opacity: .5;
}
.theme__mustard input::-ms-input-placeholder {
  color: #692790;
  opacity: .5;
}
.theme__mustard input::placeholder {
  color: #692790;
  opacity: .5;
}

.theme__mustard--dark {
  background-color: #692790;
}
.theme__mustard--dark .btn-filled {
  color: #692790;
}
.theme__mustard--dark .navbar::after,
.theme__mustard--dark::after{
  background-color: #f7f211;
}
.theme__mustard--dark input::-webkit-input-placeholder {
  color: #f7f211;
  opacity: .5;
}
.theme__mustard--dark input::-ms-input-placeholder {
  color: #f7f211;
  opacity: .5;
}
.theme__mustard--dark input::placeholder {
  color: #f7f211;
  opacity: .5;
}


/* tempAlert in Helper */

.notify__success {
	background-image: linear-gradient(to right, #868f96 0%, #596164 100%);
	border-radius: 4px;
	color: #fff;
	position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px 20px;
  width: 300px;
  z-index: 10;
}

.notify__success h4 {
	font-size: 110%;
}

.notify__success div {
	font-size: 90%;
}

/* discover */

.card-title {
  font-weight: bold;
  height: 48px;
  overflow: hidden;
}

.card-text {
  font-size: 0.8em;
}
